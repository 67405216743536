import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import PageLoader from './components/Common/PageLoader';

import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';
import { ProfileProvider, LoadingScreen } from './components/Common/ProfileContext';
import { isAuthenticated, hasRole } from './components/Common/firebase';

const waitFor = Tag => props => <Tag {...props} />;


const Post = lazy(() => import('./components/Pages/Post/Post'));
const Feed = lazy(() => import('./components/Blog/Feed'));
const CreateElement = lazy(() => import('./components/CreateElement/CreateElement'))
const Messages = lazy(() => import('./components/Messages/Messages'))
const Element = lazy(() => import('./components/Element/Element'))

const Login = lazy(() => import('./components/Pages/Login/Login'))

// Musikkeksempel
const SheetOverview = lazy(() => import('./components/Pages/Musikkeksempel/SheetOverview'))
const SheetView = lazy(() => import('./components/Pages/Musikkeksempel/SheetView'))
const Tuner = lazy(() => import('./components/Pages/Musikkeksempel/Tuner'))

// Kalender
const Calendar = lazy(() => import('./components/Calendar/Calendar'))
const CalendarCreate = lazy(() => import('./components/Pages/Calendar/Create'))

// Tilganger
const Department = lazy(() => import('./components/Pages/Tilganger/Department'))
const Group = lazy(() => import('./components/Pages/Tilganger/Group'))

// Organisasjonsvisning
const Organization = lazy(() => import('./components/Pages/Organization/Organization'))

/**
 * Class representing a route that checks if user is logged in.
 * @extends Route
 */
class RoleRequiredRoute extends Route {
    /**
    * @example <RoleRequiredRoute path="/" component={Products}>
    */
    render() {
        // call some method/function that will validate if user is logged in
        if (!hasRole(this.props.role)) {
            return <Redirect to="/feed"></Redirect>
        } else {
            return <this.props.component />
        }
    }
};

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
    '/login',
    '/tuner'
];

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    const animationName = 'rag-fadeIn'

    if (listofPages.indexOf(location.pathname) > -1) {
        return (
            <BasePage>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        <Route path="/login" component={waitFor(Login)} />
                        <Route path="/tuner" component={waitFor(Tuner)} />
                    </Switch>
                </Suspense>
            </BasePage>
        )
    }
    else if (!isAuthenticated()) {
        return (
            <Redirect to="/login" />
        )
    }
    else {
        return (
            // Layout component wrapper
            // Use <BaseHorizontal> to change layout

            <ProfileProvider>
                <LoadingScreen>
                    <Base>
                        <TransitionGroup>
                            <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                                <div>
                                    <Suspense fallback={<PageLoader />}>
                                        <Switch location={location}>
                                            <Route path="/feed" exact component={waitFor(Feed)} />
                                            <Route path="/feed/:id" component={waitFor(Post)} />

                                            <Route path="/element/:id" component={waitFor(Element)} />
                                            <Route path="/createelement" component={waitFor(CreateElement)} />
                                            <Route path="/messages" component={waitFor(Messages)} />

                                            <Route path="/calendar" exact component={waitFor(Calendar)} />
                                            <Route path="/calendar/create" component={waitFor(CalendarCreate)} />

                                            <Route path="/notes" exact component={waitFor(SheetOverview)} />
                                            <Route path="/notes/:id" component={waitFor(SheetView)} />

                                            <Route path="/department" component={waitFor(Department)} />
                                            <Route path="/group" component={waitFor(Group)} />

                                            <Route path="/organization" component={waitFor(Organization)} />

                                            <Redirect to="/organization" />
                                        </Switch>
                                    </Suspense>
                                </div>
                            </CSSTransition>
                        </TransitionGroup>
                    </Base>
                </LoadingScreen>
            </ProfileProvider>
        )
    }
}

export default withRouter(Routes);
