const Menu = [
    {
        heading: 'Main Navigation',
        translate: 'sidebar.heading.HEADER'
    },
    {
        name: 'Organisasjon',
        path: '/organization',
        icon: 'fas fa-users'
    },
    {
        name: 'Meldinger og feed',
        icon: 'fas fa-envelope',
        submenu: [
            {
                name: 'Feed',
                path: '/feed',
                icon: 'icon-grid'
            },
            {
                name: 'Meldingssenter',
                path: '/messages',
                icon: 'fas fa-inbox'
            },
            {
                name: 'Opprett et innlegg',
                path: '/createelement',
                icon: 'fas fa-i-cursor'
            }]
    },
    {
        name: 'Kalender',
        path: '/calendar',
        icon: 'fas fa-calendar'
    },
    {
        name: 'Musikkeksempel',
        path: '/notes',
        icon: 'fas fa-music'
    },
    {
        name: 'Avdelinger og grupper',
        icon: 'fas fa-users',
        submenu: [{
                name: 'Avdelinger',
                path: '/department'
            },
            {
                name: 'Grupper',
                path: '/group'
            }
        ]
    },
];

export default Menu;
