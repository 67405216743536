import React, { useState, useEffect, useContext, createContext } from 'react'
import { DB, OrgKey, UidKey, Auth, getOrgKey } from './firebase'
import { Container } from 'reactstrap'

const defaults = {
    loaded: false
}

const ProfileContext = createContext(defaults)

export function ProfileProvider({ children }) {
    const [state, setState] = useState(defaults)

    useEffect(() => {
        const fetchPerson = async (identifier) => {
            const personSnapshot = await DB.doc(`organizations/${window.localStorage.getItem(OrgKey)}/persons/${identifier}`).get()

            if (!personSnapshot.exists) return setState({ loaded: true })

            const organizationSnapshot = await DB.doc(`organizations/${getOrgKey()}`).get()

            return setState({
                loaded: true,
                profile: {
                    uid: personSnapshot.id,
                    ...personSnapshot.data()
                },
                organization: {
                    id: organizationSnapshot.id,
                    ...organizationSnapshot.data()
                }
            })
        }

        const listener = Auth.onAuthStateChanged(async user => {
            if (user) {
                await fetchPerson(user.uid)
            } else if (window.localStorage.getItem(UidKey)) {
                await fetchPerson(window.localStorage.getItem(UidKey))
            } else {
                setState({
                    loaded: true
                })
            }
        })

        return () => listener()
    }, [])

    return (
        <ProfileContext.Provider value={state}>
            {children}
        </ProfileContext.Provider>
    )
}

export function useProfile() {
    const profile = useContext(ProfileContext)

    return profile.profile
}

export function useOrganization() {
    const profile = useContext(ProfileContext)

    return profile.organization
}


export function LoadingScreen({ children }) {
    const profile = useContext(ProfileContext)

    if (!profile.loaded) return (
        <Container className="text-center mt-5">
            <h1 className="text-muted">Laster inn... <em className="fas fa-circle-notch fa-spin text-muted ml-3"></em></h1>
        </Container>
    )

    return (
        children
    )
}
