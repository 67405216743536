import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import { useState, useEffect } from 'react';

const config = {
    apiKey: "AIzaSyAcPNmanHf67zqcjkNQD-VZsC5BgXkTQTY",
    authDomain: "stian2017test.firebaseapp.com",
    databaseURL: "https://stian2017test.firebaseio.com",
    projectId: "stian2017test",
    storageBucket: "stian2017test.appspot.com",
    messagingSenderId: "1044262538928",
    appId: "1:1044262538928:web:93284828fdee24a274f575"
};

export const firebaseTimestamp = firebase.firestore.Timestamp;
export const firebaseFieldValue = firebase.firestore.FieldValue;

export const firebaseApp = firebase.initializeApp(config);

export const GoogleAuthProvider = firebase.auth.GoogleAuthProvider;

const fbdb = firebaseApp.firestore();

export const DB = fbdb;

export const Storage = firebaseApp.storage();
export const Auth = firebaseApp.auth();
export const Functions = firebaseApp.functions();

export const UidKey = 'styreportalen-uid-key';
export const OrgKey = 'styreportalen-org-key';
export const OrgNameKey = 'styreportalen-org-name-key';
export const UserNameKey = 'styreportalen-user-name-key';
export const RoleKey = 'styreportalen-role-key';
export const CalKey = 'styreportalen-calendar-key';
export const PinKey = 'styreportalen-pin-key';
export const PinFilterKey = 'styreportalen-pin-filter-key';

export const isAuthenticated = () => {
    return !!Auth.currentUser || !!localStorage.getItem(UidKey);
}

export const hasRole = (reqRoles) => {
    let roles = localStorage.getItem(RoleKey);
    if (roles) {
        roles = roles.split(",");

        if (roles.indexOf(reqRoles) >= 0) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const getOrgKey = () => window.localStorage.getItem(OrgKey)

export const useFetchDocument = (path) => {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')


    useEffect(() => {
        async function fetchDocument() {
            try {
                const snapshot = await DB.doc(path).get()

                if (!snapshot.exists) throw new Error('Dokumentet finnes ikke...')

                const data = snapshot.data()

                setData({
                    id: snapshot.id,
                    refPath: snapshot.ref.path,
                    ...data
                })
                setLoading(false)
            } catch (e) {
                setError(e.message)
                setLoading(false)
            }
        }

        fetchDocument()
    }, [path])

    return [data, loading, error]
}

export const useFetchCollection = (path) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')

    useEffect(() => {
        async function fetchCollection() {
            try {
                const snapshot = await DB.collection(path).get()

                const documents = snapshot.docs.map(doc => {
                    return {
                        id: doc.id,
                        refPath: doc.ref.path,
                        ...doc.data()
                    }
                })

                setData(documents)
                setLoading(false)
            } catch (e) {
                setError(e.message)
                setLoading(false)
            }
        }

        fetchCollection()
    }, [path])

    return [data, loading, error]
}