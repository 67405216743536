import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';

import HeaderRun from './Header.run'
import { useProfile } from '../Common/ProfileContext';
import { Auth, OrgKey, UidKey } from '../Common/firebase';
import { useHistory } from 'react-router';

import ToggleFullscreen from '../Common/ToggleFullscreen';

function Header(props) {
    const profile = useProfile()
    const history = useHistory()

    React.useEffect(HeaderRun, [])

    const toggleUserblock = e => {
        e.preventDefault()
        props.actions.toggleSetting('showUserBlock')
    }

    const toggleCollapsed = e => {
        e.preventDefault()
        props.actions.toggleSetting('isCollapsed');
        resize()
    }

    const toggleAside = e => {
        e.preventDefault()
        props.actions.toggleSetting('asideToggled');
    }
    const resize = () => {
        // all IE friendly dispatchEvent
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
        // modern dispatchEvent way
        // window.dispatchEvent(new Event('resize'));
    }

    const logOut = async () => {
        await Auth.signOut()
        window.localStorage.removeItem(OrgKey)
        window.localStorage.removeItem(UidKey)

        history.push('/')
    }

    return (
        <header className="topnavbar-wrapper">
            { /* START Top Navbar */}
            <nav className="navbar topnavbar">
                { /* START navbar header */}
                <div className="navbar-header">
                    <a className="navbar-brand" href="#/">
                        <div className="brand-logo">
                            <img className="img-fluid" src="img/logo.png" alt="App Logo" />
                        </div>
                        <div className="brand-logo-collapsed">
                            <img className="img-fluid" src="img/logo-single.png" alt="App Logo" />
                        </div>
                    </a>
                </div>
                { /* END navbar header */}

                { /* START Left navbar */}
                <ul className="navbar-nav mr-auto flex-row">
                    <li className="nav-item">
                        { /* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
                        <a href="" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={toggleCollapsed}>
                            <em className="fas fa-bars"></em>
                        </a>
                        { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
                        <a href="" className="nav-link sidebar-toggle d-md-none" onClick={toggleAside}>
                            <em className="fas fa-bars"></em>
                        </a>
                    </li>
                    { /* START User avatar toggle */}
                    {profile && <li className="nav-item d-none d-md-block">
                        <a className="nav-link" onClick={toggleUserblock}>
                            <em className="icon-user"></em>
                        </a>
                    </li>}
                    { /* END User avatar toggle */}
                    { /* START lock screen */}
                    <li className="nav-item d-none d-md-block">
                        {profile && <a className="nav-link" onClick={logOut}>
                            <em className="fas fa-sign-out-alt"></em>
                        </a>}
                    </li>
                    { /* END lock screen */}

                    <li className="nav-item d-none d-md-block">
                        <ToggleFullscreen className="nav-link" />
                    </li>
                </ul>
                { /* END Left navbar */}
            </nav>
            { /* END Top Navbar */}
        </header>
    )
}

Header.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);