import React from 'react';
import { Collapse } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useProfile } from '../Common/ProfileContext';

function SidebarUserBlock() {
    const showUserBlock = useSelector(state => { return state.settings.showUserBlock ? state.settings.showUserBlock : false })

    const profile = useProfile()

    return (
        <>
            <Collapse id="user-block" isOpen={showUserBlock}>
                <div>
                    <div className="item user-block">
                        <div className="user-block-picture">
                            <div className="user-block-status">
                                <img className="img-thumbnail rounded-circle" src={profile ? profile.image_uri && profile.image_uri.thumbnail : 'img/user/02.jpg'} alt="Avatar" width="60" height="60" />
                                <div className="circle bg-success circle-lg"></div>
                            </div>
                        </div>

                        <div className="user-block-info">
                            <span className="user-block-name">{profile && profile.displayName}</span>
                            <span className="user-block-role">{profile && profile.role}</span>
                        </div>
                    </div>
                </div>
            </Collapse>
        </>
    )
}

export default SidebarUserBlock